import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  TextField,
  Pagination,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'
import { backendServices } from '../services/backendService'

// Define the table head for tickets
const TABLE_HEAD = [
  { id: 'logo', label: 'Logo', alignRight: false },
  { id: 'ticketName', label: 'Ticket Name', alignRight: false },
  { id: 'numberOfTickets', label: 'Number of Tickets', alignRight: false },
  { id: 'ticketCost', label: 'Ticket Cost', alignRight: false },
  { id: 'ticketDollerValue', label: 'Dollar Value', alignRight: false },
  { id: 'ticketDescription', label: 'Description', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
]

// Component to render the toolbar for filtering tickets
const TicketListToolbar = ({ filterName, onFilterName }) => (
  <Stack direction='row' spacing={2} style={{ padding: '20px' }}>
    <TextField
      label='Filter by Ticket Name'
      variant='outlined'
      size='medium'
      value={filterName}
      onChange={onFilterName}
    />
  </Stack>
)

// Component to render the list of tickets with pagination and sorting
const TicketList = ({ refreshTrigger, onEdit, onDelete }) => {
  const [tickets, setTickets] = useState([])
  const [filterName, setFilterName] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('ticketName')
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTickets()
  }, [refreshTrigger])

  const fetchTickets = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/tickets/allTickets`
      )
      if (response.data) {
        setTickets(response.data)
      }
    } catch (error) {
      console.error('Error fetching tickets:', error)
    } finally {
      // setLoading(false); // Hide loader after fetch completes or fails
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value)
    setPage(1)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOpenMenu = (event, ticket) => {
    setAnchorEl(event.currentTarget)
    setSelectedTicket(ticket)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedTicket(null)
  }

  const handleEdit = () => {
    onEdit(selectedTicket)
    handleCloseMenu()
  }

  const handleDelete = () => {
    onDelete(selectedTicket._id)
    handleCloseMenu()
  }

  const filteredTickets = tickets.filter(ticket =>
    ticket.ticketName.toLowerCase().includes(filterName.toLowerCase())
  )

  const sortedTickets = filteredTickets.sort((a, b) => {
    const isAsc = order === 'asc'
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1
    return 0
  })

  const paginatedTickets = sortedTickets.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  )

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'black'
      }}
    >
      <TicketListToolbar
        filterName={filterName}
        onFilterName={handleFilterByName}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" marginTop="-50px">
          <CircularProgress />
        </Box>
      ) : (<>
        <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          background: 'black'
        }}
      >
          <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? 'right' : 'left'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      style={{ backgroundColor: 'black', color: '#674eff' }}
                    >
                      {headCell.id === 'logo' ? (
                        <Typography variant='subtitle1'>Ticket Logo</Typography>
                      ) : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTickets.map(row => (
                  <TableRow hover key={row._id} tabIndex={-1}>
                    <TableCell align='left'>
                      <img
                        src={row.ticketLogoUrl}
                        alt={row.ticketName}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </TableCell>
                    <TableCell align='left'>{row.ticketName}</TableCell>
                    <TableCell align='left'>{row.numberOfTickets}</TableCell>
                    <TableCell align='left'>{row.ticketCost}</TableCell>
                    <TableCell align='left'>{row.ticketDollerValue}</TableCell>
                    <TableCell align='left'>{row.ticketDescription}</TableCell>
                    <TableCell align='left'>
                      <IconButton
                        aria-controls='ticket-menu'
                        aria-haspopup='true'
                        onClick={event => handleOpenMenu(event, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id='ticket-menu'
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        sx={{
                          '& .MuiMenu-paper': {
                            backgroundColor: 'black'
                          }
                        }}
                      >
                        <MenuItem onClick={handleEdit}>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary='Edit' />
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText primary='Delete' />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper></>)}
      <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
        <Pagination
          count={Math.ceil(sortedTickets.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color='primary'
          showFirstButton
          showLastButton
        />
      </Stack>
    </Card>
  )
}

// Component to render the dialog for adding a new ticket
const AddTicket = ({ open, onClose, onTicketAdded }) => {
  const initialTicketData = {
    ticketName: '',
    numberOfTickets: '',
    ticketCost: '',
    ticketDollerValue: '',
    ticketDescription: '',
    ticketLogoUrl: ''
  }

  const [ticketData, setTicketData] = useState(initialTicketData)

  const handleChange = e => {
    const { name, value } = e.target
    setTicketData({
      ...ticketData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/tickets/addTickets`,
        ticketData
      )
      if (response.data) {
        onTicketAdded(response.data)
        setTicketData(initialTicketData) // Reset the input fields
        onClose()
      }
    } catch (error) {
      console.error('Error adding ticket:', error)
    }
  }

  return (
    <Dialog open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
          color: 'white',
          boxShadow: 'none', // Remove the shadow
          border: '1px solid gray',
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
      }}>
      <DialogTitle style={{ background: 'black' }}>Add Ticket</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          autoFocus
          margin='dense'
          name='ticketName'
          label='Ticket Name'
          type='text'
          fullWidth
          variant='standard'
          value={ticketData.ticketName}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='numberOfTickets'
          label='Number of Tickets'
          type='number'
          fullWidth
          variant='standard'
          value={ticketData.numberOfTickets}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketLogoUrl'
          label='Ticket Logo URL'
          type='text'
          fullWidth
          variant='standard'
          value={ticketData.ticketLogoUrl}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketCost'
          label='Ticket Cost'
          type='number'
          fullWidth
          variant='standard'
          value={ticketData.ticketCost}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketDollerValue'
          label='Dollar Value'
          type='number'
          fullWidth
          variant='standard'
          value={ticketData.ticketDollerValue}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketDescription'
          label='Description'
          type='text'
          fullWidth
          variant='standard'
          value={ticketData.ticketDescription}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

// Component to render the dialog for editing a ticket
const EditTicket = ({ open, onClose, ticket, onTicketUpdated }) => {
  const [ticketData, setTicketData] = useState(ticket)

  useEffect(() => {
    if (ticket) {
      setTicketData(ticket)
    }
  }, [ticket])

  const handleChange = e => {
    const { name, value } = e.target
    setTicketData({
      ...ticketData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/tickets/${ticketData._id}`,
        ticketData
      )
      if (response.data) {
        onTicketUpdated(response.data)
        onClose()
      }
    } catch (error) {
      console.error('Error updating ticket:', error)
    }
  }

  return (
    <Dialog open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
          color: 'white',
          boxShadow: 'none', // Remove the shadow
          border: '1px solid gray',
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
      }}>
      <DialogTitle style={{ background: 'black' }}>Edit Ticket</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          autoFocus
          margin='dense'
          name='ticketName'
          label='Ticket Name'
          type='text'
          fullWidth
          variant='standard'
          value={ticketData.ticketName}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='numberOfTickets'
          label='Number of Tickets'
          type='number'
          fullWidth
          variant='standard'
          value={ticketData.numberOfTickets}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketLogoUrl'
          label='Ticket Logo URL'
          type='text'
          fullWidth
          variant='standard'
          value={ticketData.ticketLogoUrl}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketCost'
          label='Ticket Cost'
          type='number'
          fullWidth
          variant='standard'
          value={ticketData.ticketCost}
          onChange={handleChange}
        />

        <TextField
          margin='dense'
          name='ticketDollerValue'
          label='Dollar Value'
          type='number'
          fullWidth
          variant='standard'
          value={ticketData.ticketDollerValue}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='ticketDescription'
          label='Description'
          type='text'
          fullWidth
          variant='standard'
          value={ticketData.ticketDescription}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

// Component to render the confirmation dialog for deleting a ticket
const ConfirmDeleteDialog = ({ open, onClose, onConfirm }) => (
  <Dialog open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
    PaperProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
        color: 'white',
        boxShadow: 'none', // Remove the shadow
        border: '1px solid gray',
      }
    }}
    BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
    }}>
    <DialogTitle style={{ background: 'black' }}>Confirm Delete</DialogTitle>
    <DialogContent style={{ background: 'black' }}>
      <Typography>Are you sure you want to delete this ticket?</Typography>
    </DialogContent>
    <DialogActions style={{ background: 'black' }}>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} color='error'>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

const TicketManager = () => {
  const [addOpen, setAddOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [refreshTrigger, setRefreshTrigger] = useState(0)

  const handleAddOpen = () => {
    setAddOpen(true)
  }

  const handleAddClose = () => {
    setAddOpen(false)
  }

  const handleTicketAdded = () => {
    setRefreshTrigger(refreshTrigger + 1)
  }

  const handleEditOpen = ticket => {
    setSelectedTicket(ticket)
    setEditOpen(true)
  }

  const handleEditClose = () => {
    setEditOpen(false)
  }

  const handleTicketUpdated = () => {
    setRefreshTrigger(refreshTrigger + 1)
  }

  const handleDeleteOpen = ticketId => {
    setSelectedTicket(ticketId)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const handleDeleteConfirm = async () => {
    try {
      await backendServices.Delete(
        `${process.env.REACT_APP_BASE_URL}/tickets/${selectedTicket}`
      )
      setRefreshTrigger(refreshTrigger + 1)
      handleDeleteClose()
    } catch (error) {
      console.error('Error deleting ticket:', error)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Container sx={{ flex: '0 0 auto' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mb={3}
        >
          <Typography variant='h4' gutterBottom>
            Ticket Management
          </Typography>
          <Button variant='contained' onClick={handleAddOpen}>
            Add Ticket
          </Button>
        </Stack>
      </Container>
      <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <TicketList
          refreshTrigger={refreshTrigger}
          onEdit={handleEditOpen}
          onDelete={handleDeleteOpen}
        />
      </Box>
      <AddTicket
        open={addOpen}
        onClose={handleAddClose}
        onTicketAdded={handleTicketAdded}
      />
      {selectedTicket && (
        <EditTicket
          open={editOpen}
          onClose={handleEditClose}
          ticket={selectedTicket}
          onTicketUpdated={handleTicketUpdated}
        />
      )}
      <ConfirmDeleteDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  )
}

export default TicketManager

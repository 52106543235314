import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  TextField,
  Pagination,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Switch,
  Box,
  Paper,
  CircularProgress,
  CardContent,
} from '@mui/material'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'
import { backendServices } from '../services/backendService'
import useTimeout from '@mui/utils/useTimeout'
import { useNavigate } from 'react-router-dom';
import { Upload, X } from "lucide-react";

const TABLE_HEAD = [
  { id: 'logo', label: 'Logo', alignRight: false },
  { id: 'taskName', label: 'Task Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'taskPoints', label: 'Task Points', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'isDisabled', label: 'Status', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
]

const TaskListToolbar = ({ filterName, onFilterName }) => {
  const navigate = useNavigate();

  const handleReorderClick = () => {
    navigate('/dashboard/ReorderTask'); // Replace with your desired route
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      style={{ padding: '20px' }}
    >
      <TextField
        label="Filter by Task Name"
        variant="outlined"
        size="medium"
        value={filterName}
        onChange={onFilterName}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleReorderClick}
      >
        Reorder Tasks
      </Button>
    </Stack>
  );
};

const TaskList = ({ tasks, onEdit, onDelete, onToggleStatus }) => {
  const [filterName, setFilterName] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('taskName')
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(50)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate an API call
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000) // Simulate loading time

    return () => clearTimeout(timer) // Clean up timer on unmount
  }, [])

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value)
    setPage(1)
  }

  const handleToggleStatus = async (taskId, currentStatus) => {
    try {
      await onToggleStatus(taskId, !currentStatus)
    } catch (error) {
      console.error('Error toggling task status:', error)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOpenMenu = (event, task) => {
    setAnchorEl(event.currentTarget)
    setSelectedTask(task)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedTask(null)
  }

  const handleEdit = () => {
    onEdit(selectedTask)
    handleCloseMenu()
  }

  const handleDelete = () => {
    onDelete(selectedTask._id)
    handleCloseMenu()
  }

  const filteredTasks = tasks.filter(task =>
    task.taskName.toLowerCase().includes(filterName.toLowerCase())
  )

  const sortedTasks = filteredTasks.sort((a, b) => {
    const isAsc = order === 'asc'
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1
    return 0
  })

  const paginatedTasks = sortedTasks.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  )

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'black'
      }}
    >
      <TaskListToolbar
        filterName={filterName}
        onFilterName={handleFilterByName}
      />
      {loading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100vh'
          marginTop='-50px'
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              background: 'black'
            }}
          >
            <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ backgroundColor: 'black', color: '#674eff' }}
                      >
                        {headCell.id === 'logo' ? (
                          <Typography variant='subtitle1'>Logo</Typography>
                        ) : (
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ height: '400px', overflow: 'auto' }}>
                  {paginatedTasks.map(row => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell align='left'>
                        <img
                          src={row.taskLogoUrl}
                          alt={row.taskName}
                          style={{ width: '50px', height: '50px' }}
                        />
                      </TableCell>
                      <TableCell align='left'>{row.taskName}</TableCell>
                      <TableCell align='left'>{row.description}</TableCell>
                      <TableCell align='left'>{row.taskPoints}</TableCell>
                      <TableCell align='left'>{row.category}</TableCell>
                      <TableCell align='left'>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!row.isDisabled} // Toggle is on when task is enabled
                              onChange={() =>
                                handleToggleStatus(row._id, row.isDisabled)
                              }
                              color='primary'
                            />
                          }
                          label={row.isDisabled ? 'Disabled' : 'Enabled'}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <IconButton
                          aria-controls='task-menu'
                          aria-haspopup='true'
                          onClick={event => handleOpenMenu(event, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id='task-menu'
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          sx={{
                            '& .MuiMenu-paper': {
                              backgroundColor: 'black'
                            }
                          }}
                        >
                          <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary='Edit' />
                          </MenuItem>
                          {/* TODO: dont remove this code  */}
                          {/* <MenuItem onClick={handleDelete}>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary='Delete' />
                          </MenuItem> */}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
      <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
        <Pagination
          count={Math.ceil(sortedTasks.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color='primary'
          showFirstButton
          showLastButton
        />
      </Stack>
    </Card>
  )
}

const AddTask = ({ open, onClose, onTaskAdded }) => {
  const initialTaskData = {
    taskName: '',
    description: '',
    taskPoints: '',
    socialUrl: '',
    taskLogo: null,
    category: '',
    isDisabled: false,
    isStoryTask: false,
    isRepeatTask: false,
    storyCaption: '',
    repeatTaskFrequency: ''
  };

  const [taskData, setTaskData] = useState(initialTaskData);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleChange = (e) => {
    const { name, value, checked, type, files } = e.target;

    if (type === 'file') {
      const file = files[0];
      if (file) {
        // Create preview URL for the image
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrl(fileUrl);
        setTaskData(prevState => ({
          ...prevState,
          [name]: file
        }));
      }
    } else {
      setTaskData(prevState => ({
        ...prevState,
        [name]: name === 'isDisabled' || name === 'isStoryTask' || name === 'isRepeatTask'
          ? checked
          : value
      }));
    }
  };

  const handleRemoveImage = () => {
    setTaskData(prevState => ({
      ...prevState,
      taskLogo: null
    }));
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.keys(taskData).forEach(key => {
      if (key === 'taskLogo') {
        if (taskData[key]) {
          formData.append('taskLogo', taskData[key]);
        }
      } else {
        formData.append(key, taskData[key]);
      }
    });

    try {
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/task/addTask`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (response.data.success) {
        onTaskAdded(response.data.task);
        setTaskData(initialTaskData);
        handleRemoveImage();
        onClose();
      }
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          boxShadow: 'none',
          border: '1px solid gray'
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' }
      }}
    >
      <DialogTitle style={{ background: 'black' }}>Add Task</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          autoFocus
          margin='dense'
          name='taskName'
          label='Task Name'
          type='text'
          fullWidth
          variant='standard'
          value={taskData.taskName}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='description'
          label='Description'
          type='text'
          fullWidth
          variant='standard'
          value={taskData.description}
          onChange={handleChange}
        />
        
        <h4 style={{marginTop:"20px"}}>Upload Task Logo</h4>
        {/* File Upload Area */}
        <Card className="mt-4 border-dashed border-2 border-gray-400" style={{background:"black"}}>
          <CardContent className="flex flex-col items-center justify-center p-6">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="taskLogo"
              type="file"
              onChange={handleChange}
              name="taskLogo"
            />
            {!previewUrl ? (
              <label htmlFor="taskLogo" className="w-full">
                <div className="flex flex-col items-center justify-center gap-2 cursor-pointer">
                  <Upload className="h-8 w-8 text-gray-400" />
                  <p className="text-sm text-gray-400">Click to upload task logo</p>
                  <p className="text-xs text-gray-500">PNG, JPG up to 5MB</p>
                </div>
              </label>
            ) : (
              <div className="relative w-full">
                <img
                  src={previewUrl}
                  alt="Task logo preview"
                  className="w-full h-40 object-contain"
                />
                <button
                  onClick={handleRemoveImage}
                  className="absolute top-0 right-0 p-1 bg-red-500 rounded-full hover:bg-red-600"
                >
                  <X className="h-4 w-4 text-white" />
                </button>
              </div>
            )}
          </CardContent>
        </Card>

        <TextField
          margin='dense'
          name='taskPoints'
          label='Task Points'
          type='number'
          fullWidth
          variant='standard'
          value={taskData.taskPoints}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='socialUrl'
          label='Social URL'
          type='text'
          fullWidth
          variant='standard'
          value={taskData.socialUrl}
          onChange={handleChange}
        />
        <TextField
          select
          margin='dense'
          name='category'
          label='Category'
          fullWidth
          variant='standard'
          value={taskData.category}
          onChange={handleChange}
        >
          <MenuItem value='register' style={{ background: 'black' }}>Register</MenuItem>
          <MenuItem value='socials' style={{ background: 'black' }}>JoinCommunity</MenuItem>
          <MenuItem value='videos' style={{ background: 'black' }}>Videos</MenuItem>
          <MenuItem value='Socialtask' style={{ background: 'black' }}>SocialTasks</MenuItem>
          <MenuItem value='Partner' style={{ background: 'black' }}>PartnersTasks</MenuItem>
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={taskData.isDisabled}
              onChange={handleChange}
              name='isDisabled'
              color='primary'
            />
          }
          label='Disabled'
        />
        <FormControlLabel
          control={
            <Switch
              checked={taskData.isStoryTask}
              onChange={handleChange}
              name='isStoryTask'
              color='primary'
            />
          }
          label='Story Task'
        />
        {taskData.isStoryTask && (
          <TextField
            margin='dense'
            name='storyCaption'
            label='Story Caption'
            type='text'
            fullWidth
            variant='standard'
            value={taskData.storyCaption}
            onChange={handleChange}
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={taskData.isRepeatTask}
              onChange={handleChange}
              name='isRepeatTask'
              color='primary'
            />
          }
          label='Repeat Task'
        />
        {taskData.isRepeatTask && (
          <TextField
            margin='dense'
            name='repeatTaskFrequency'
            label='Repeat Task Frequency (hours)'
            type='number'
            fullWidth
            variant='standard'
            value={taskData.repeatTaskFrequency}
            onChange={handleChange}
          />
        )}
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};


const EditTask = ({ open, onClose, task, onTaskUpdated }) => {
  const [taskData, setTaskData] = useState({
    taskName: '',
    description: '',
    taskPoints: '',
    socialUrl: '',
    taskLogo: null,
    taskLogoUrl: '',
    category: '',
    isDisabled: false,
    isStoryTask: false,
    isRepeatTask: false,
    storyCaption: '',
    repeatTaskFrequency: ''
  });

  useEffect(() => {
    if (task) {
      setTaskData({
        ...task,
        repeatTaskFrequency: task.isRepeatTask ? task.repeatTaskFrequency : '',
        taskLogo: null // Reset taskLogo as it will be a new file upload
      });
    }
  }, [task]);

  const handleChange = (e) => {
    const { name, value, checked, type, files } = e.target;

    if (type === 'file') {
      const file = files[0];
      if (file) {
        // Revoke previous object URL if it exists
        if (taskData.taskLogo && taskData.taskLogoUrl.startsWith('blob:')) {
          URL.revokeObjectURL(taskData.taskLogoUrl);
        }
        
        setTaskData(prevState => ({
          ...prevState,
          taskLogo: file,
          taskLogoUrl: URL.createObjectURL(file)
        }));
      }
    } else if (name === 'isRepeatTask') {
      setTaskData(prevState => ({
        ...prevState,
        isRepeatTask: checked,
        repeatTaskFrequency: checked ? prevState.repeatTaskFrequency : ''
      }));
    } else if (name === 'isStoryTask') {
      setTaskData(prevState => ({
        ...prevState,
        isStoryTask: checked,
        storyCaption: checked ? prevState.storyCaption : ''
      }));
    } else {
      setTaskData(prevState => ({
        ...prevState,
        [name]: name === 'isDisabled' ? checked : value
      }));
    }
  };

  const handleRemoveImage = () => {
    // Revoke object URL if it exists
    if (taskData.taskLogo && taskData.taskLogoUrl.startsWith('blob:')) {
      URL.revokeObjectURL(taskData.taskLogoUrl);
    }
    
    setTaskData(prevState => ({
      ...prevState,
      taskLogo: null,
      taskLogoUrl: '' // Clear the URL as well
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.keys(taskData).forEach(key => {
      if (key === 'taskLogo') {
        if (taskData[key]) {
          formData.append('taskLogo', taskData[key]);
        }
      } else if (key !== 'taskLogoUrl') { // Don't send the URL to the server
        formData.append(key, taskData[key]);
      }
    });

    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/task/${taskData._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data) {
        onTaskUpdated(response.data);
        onClose();
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  // Cleanup function for object URLs
  useEffect(() => {
    return () => {
      if (taskData.taskLogo && taskData.taskLogoUrl.startsWith('blob:')) {
        URL.revokeObjectURL(taskData.taskLogoUrl);
      }
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          boxShadow: 'none',
          border: '1px solid gray'
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' }
      }}
    >
      <DialogTitle style={{ background: 'black' }}>Edit Task</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          autoFocus
          margin='dense'
          name='taskName'
          label='Task Name'
          type='text'
          fullWidth
          variant='standard'
          value={taskData.taskName}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='description'
          label='Description'
          type='text'
          fullWidth
          variant='standard'
          value={taskData.description}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='taskPoints'
          label='Task Points'
          type='number'
          fullWidth
          variant='standard'
          value={taskData.taskPoints}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='socialUrl'
          label='Social URL'
          type='text'
          fullWidth
          variant='standard'
          value={taskData.socialUrl}
          onChange={handleChange}
        />

        <h4 style={{marginTop:"20px"}}>Upload Task Logo</h4>
        {/* File Upload Area */}
        <Card className="mt-4 border-dashed border-2 border-gray-400" style={{background:"black"}}>
          <CardContent className="flex flex-col items-center justify-center p-6">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="taskLogo"
              type="file"
              onChange={handleChange}
              name="taskLogo"
            />
            {!taskData.taskLogoUrl ? (
              <label htmlFor="taskLogo" className="w-full">
                <div className="flex flex-col items-center justify-center gap-2 cursor-pointer">
                  <Upload className="h-8 w-8 text-gray-400" />
                  <p className="text-sm text-gray-400">Click to upload task logo</p>
                  <p className="text-xs text-gray-500">PNG, JPG up to 5MB</p>
                </div>
              </label>
            ) : (
              <div className="relative w-full">
                <img
                  src={taskData.taskLogoUrl}
                  alt="Task logo preview"
                  className="w-full h-40 object-contain"
                />
                <button
                  onClick={handleRemoveImage}
                  className="absolute top-0 right-0 p-1 bg-red-500 rounded-full hover:bg-red-600"
                >
                  <X className="h-4 w-4 text-white" />
                </button>
              </div>
            )}
          </CardContent>
        </Card>

        <TextField
          select
          margin='dense'
          name='category'
          label='Category'
          fullWidth
          variant='standard'
          value={taskData.category}
          onChange={handleChange}
        >
          <MenuItem value='register' style={{ background: 'black' }}>Register</MenuItem>
          <MenuItem value='socials' style={{ background: 'black' }}>JoinCommunity</MenuItem>
          <MenuItem value='videos' style={{ background: 'black' }}>Videos</MenuItem>
          <MenuItem value='Socialtask' style={{ background: 'black' }}>SocialTasks</MenuItem>
          <MenuItem value='Partner' style={{ background: 'black' }}>PartnersTasks</MenuItem>
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={taskData.isDisabled}
              onChange={handleChange}
              name='isDisabled'
              color='primary'
            />
          }
          label='Disabled'
        />
        <FormControlLabel
          control={
            <Switch
              checked={taskData.isStoryTask}
              onChange={handleChange}
              name='isStoryTask'
              color='primary'
            />
          }
          label='Story Task'
        />
        {taskData.isStoryTask && (
          <TextField
            margin='dense'
            name='storyCaption'
            label='Story Caption'
            type='text'
            fullWidth
            variant='standard'
            value={taskData.storyCaption}
            onChange={handleChange}
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={taskData.isRepeatTask}
              onChange={handleChange}
              name='isRepeatTask'
              color='primary'
            />
          }
          label='Repeat Task'
        />
        {taskData.isRepeatTask && (
          <TextField
            margin='dense'
            name='repeatTaskFrequency'
            label='Repeat Task Frequency (hours)'
            type='number'
            fullWidth
            variant='standard'
            value={taskData.repeatTaskFrequency}
            onChange={handleChange}
          />
        )}
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose} color='secondary'>Cancel</Button>
        <Button onClick={handleSubmit} color='primary'>Update</Button>
      </DialogActions>
    </Dialog>
  );
};


const ConfirmDeleteDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth='sm'
    PaperProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
        color: 'white',
        boxShadow: 'none', // Remove the shadow
        border: '1px solid gray'
      }
    }}
    BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
    }}
  >
    <DialogTitle style={{ background: 'black' }}>Confirm Delete</DialogTitle>
    <DialogContent style={{ background: 'black' }}>
      <Typography>Are you sure you want to delete this task?</Typography>
    </DialogContent>
    <DialogActions style={{ background: 'black' }}>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} color='error'>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

const TaskManager = () => {
  const [tasks, setTasks] = useState([])
  const [addOpen, setAddOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)

  useEffect(() => {
    fetchTasks()
  }, [])

  // Simulate loading tasks

  const fetchTasks = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/task/getall`
      )
      if (response.data.success) {
        setTasks(response.data.tasks)
      }
    } catch (error) {
      console.error('Error fetching tasks:', error)
      // }finally {
      //   // setLoading(false); // Hide loader after fetch completes or fails
      //   setTimeout(()=>{
      //    setLoading(false);
      //   },1000)
    }
  }

  const handleAddOpen = () => {
    setAddOpen(true)
  }

  const handleAddClose = () => {
    setAddOpen(false)
  }

  const handleTaskAdded = newTask => {
    setTasks([...tasks, newTask])
    setAddOpen(false)
  }

  const handleEditOpen = task => {
    setSelectedTask({ ...task })
    setEditOpen(true)
  }

  const handleEditClose = () => {
    setEditOpen(false)
    setSelectedTask(null)
  }

  const handleTaskUpdated = updatedTask => {
    const updatedTasks = tasks.map(task =>
      task._id === updatedTask._id ? updatedTask : task
    )
    setTasks(updatedTasks)
    setEditOpen(false)
  }

  const handleDeleteOpen = taskId => {
    setSelectedTask(taskId)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setSelectedTask(null)
  }

  const handleDeleteConfirm = async () => {
    try {
      await backendServices.Delete(
        `${process.env.REACT_APP_BASE_URL}/task/${selectedTask}`
      )
      const updatedTasks = tasks.filter(task => task._id !== selectedTask)
      setTasks(updatedTasks)
      setDeleteOpen(false)
    } catch (error) {
      console.error('Error deleting task:', error)
    }
  }

  const handleToggleStatus = async (taskId, newStatus) => {
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/task/${taskId}`,
        { isDisabled: newStatus }
      )
      if (response.data) {
        const updatedTasks = tasks.map(task =>
          task._id === taskId ? { ...task, isDisabled: newStatus } : task
        )
        setTasks(updatedTasks)
      }
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Container sx={{ flex: '0 0 auto', margin: '0px' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mb={3}
        >
          <Typography variant='h4' gutterBottom>
            Task Management
          </Typography>
          <Button variant='contained' onClick={handleAddOpen}>
            Add Task
          </Button>
        </Stack>
      </Container>
      <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <TaskList
          tasks={tasks}
          onEdit={handleEditOpen}
          onDelete={handleDeleteOpen}
          onToggleStatus={handleToggleStatus}
        />
      </Box>
      <AddTask
        open={addOpen}
        onClose={handleAddClose}
        onTaskAdded={handleTaskAdded}
      />
      {selectedTask && (
        <EditTask
          open={editOpen}
          onClose={handleEditClose}
          task={selectedTask}
          onTaskUpdated={handleTaskUpdated}
        />
      )}
      <ConfirmDeleteDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  )
}

export default TaskManager

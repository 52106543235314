// import React, { useState, useEffect } from 'react';
// import { 
//   Typography, FormControl, InputLabel, Select, MenuItem, 
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
//   Paper, Button, TextField, Box, Card, Snackbar, Alert
// } from '@mui/material';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { backendServices } from '../services/backendService';

// const TaskReorderComponent = () => {
//   const categories = [
//     { value: 'Register', label: 'Register' },
//     { value: 'socials', label: 'Join Community' },
//     { value: 'videos', label: 'Videos' },
//     { value: 'Socialtask', label: 'Social Tasks' },
//     { value: 'Partner', label: 'Partners Tasks' }
//   ];

//   const [selectedCategory, setSelectedCategory] = useState('socials');
//   const [tasks, setTasks] = useState([]);
//   const [movedTaskId, setMovedTaskId] = useState('');
//   const [newPosition, setNewPosition] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [error, setError] = useState(null);
//   const tasksPerPage = 10;

//   useEffect(() => {
//     if (selectedCategory) {
//       fetchTasks(selectedCategory);
//     }
//   }, [selectedCategory]);

//   const fetchTasks = async (category) => {
//     try {
//       // Using backendServices.Get for fetching tasks
//       const response = await backendServices.Get(
//         `${process.env.REACT_APP_BASE_URL}/task/getall?category=${category}`
//       );
  
//       setTasks(response.data.tasks);
//     } catch (error) {
//       console.error('Error fetching tasks:', error);
//       setError('Error fetching tasks: ' + error.message);
//     }
//   };

//   const handleCategoryChange = (event) => {
//     setSelectedCategory(event.target.value);
//     setCurrentPage(1);
//   };


//   const handleReorderTasks = async () => {
//     if (!movedTaskId || !newPosition) {
//       setError("Please enter both moved task ID and new position.");
//       return;
//     }
  
//     try {
//       // Using backendServices.Post for the API call
//       const response = await backendServices.Post(
//         `${process.env.REACT_APP_BASE_URL}/task/reorder`,
//         {
//           category: selectedCategory,
//           movedTaskId: parseInt(movedTaskId, 10),
//           newPosition: parseInt(newPosition, 10),
//         }
//       );
  
//       // Assuming response data contains status info like success or error
//       if (response.data.status !== 'success') {
//         throw new Error(response.data.message || 'Failed to reorder tasks');
//       }
  
//       fetchTasks(selectedCategory);
//       setMovedTaskId('');  // Reset after successful reordering
//       setNewPosition('');  // Reset after successful reordering
//     } catch (error) {
//       console.error('Error reordering tasks:', error);
//       setError('Error reordering tasks: ' + error.message);
//     }
//   };

//   const onDragEnd = async (result) => {
//     if (!result.destination) {
//       return;
//     }

//     const items = Array.from(tasks);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     // Update categoryOrderId for all affected items
//     const updatedItems = items.map((item, index) => ({
//       ...item,
//       categoryOrderId: index + 1
//     }));

//     setTasks(updatedItems);

//     try {
//       const response = await fetch('http://localhost:4000/api/v1/task/reorder', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           category: selectedCategory,
//           movedTaskId: parseInt(reorderedItem.categoryOrderId, 10),
//           newPosition: result.destination.index + 1
//         }),
//       });

//       const data = await response.json();

//       if (!response.ok) {
//         throw new Error(data.message || 'Failed to update task order on server');
//       }
//     } catch (error) {
//       setError('Error updating task order: ' + error.message);
//       fetchTasks(selectedCategory);  // Refresh the task list to its original state
//     }
//   };

//   const handleCloseError = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setError(null);
//   };

//   const indexOfLastTask = currentPage * tasksPerPage;
//   const indexOfFirstTask = indexOfLastTask - tasksPerPage;
//   const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

//   const handleNextPage = () => {
//     if (currentPage < Math.ceil(tasks.length / tasksPerPage)) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   return (
//     <>
//       <Typography variant="h4" sx={{ margin: '20px', color: 'white' }}>
//         Reorder Tasks
//       </Typography>
//       <Card
//         style={{
//           background: 'black',
//           color: 'white',
//           margin: '20px',
//           padding: '20px',
//           height: 'calc(100vh - 40px)',
//           display: 'flex',
//           flexDirection: 'column',
//         }}
//       >
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
//           <FormControl variant="standard" sx={{ width: '25%', color: 'white' }}>
//             <InputLabel sx={{ color: 'white' }}>Select Category</InputLabel>
//             <Select value={selectedCategory} onChange={handleCategoryChange} sx={{ color: 'white' }}>
//               {categories.map((category) => (
//                 <MenuItem key={category.value} value={category.value} style={{ background: 'black', color: 'white' }}>
//                   {category.label}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           <TextField
//             label="Moved Task Order ID"
//             value={movedTaskId}
//             onChange={(e) => setMovedTaskId(e.target.value)}
//             margin="normal"
//             sx={{ width: '25%', color: 'white' }}
//             InputLabelProps={{ style: { color: 'white' } }}
//             inputProps={{ style: { color: 'white' } }}
//           />

//           <TextField
//             label="New Position"
//             value={newPosition}
//             onChange={(e) => setNewPosition(e.target.value)}
//             margin="normal"
//             type="number"
//             sx={{ width: '25%', color: 'white' }}
//             InputLabelProps={{ style: { color: 'white' } }}
//             inputProps={{ style: { color: 'white' } }}
//           />

//           <Button 
//             variant="contained" 
//             color="primary" 
//             onClick={handleReorderTasks} 
//             sx={{ height: 'fit-content', alignSelf: 'center' }}
//           >
//             Reorder Tasks
//           </Button>
//         </Box>

//         {selectedCategory && (
//           <DragDropContext onDragEnd={onDragEnd}>
//             <Droppable droppableId="tasks">
//               {(provided) => (
//                 <TableContainer component={Paper} sx={{ background: 'black', color: 'white' }} {...provided.droppableProps} ref={provided.innerRef}>
//                   <Table>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell sx={{ color: '#674eff' }}>Order Id</TableCell>
//                         <TableCell sx={{ color: '#674eff' }}>Task Logo</TableCell>
//                         <TableCell sx={{ color: '#674eff' }}>Task Name</TableCell>
//                         <TableCell sx={{ color: '#674eff' }}>Task Points</TableCell>
//                         <TableCell sx={{ color: '#674eff' }}>Social URL</TableCell>
//                         <TableCell sx={{ color: '#674eff' }}>Is Disabled</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {currentTasks.map((task, index) => (
//                         <Draggable key={task._id} draggableId={task._id.toString()} index={index}>
//                           {(provided) => (
//                             <TableRow
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                               {...provided.dragHandleProps}
//                             >
//                               <TableCell sx={{ color: 'white' }}>{task.categoryOrderId}</TableCell>
//                               <TableCell sx={{ color: 'white' }}>
//                                 <img src={task.taskLogoUrl} alt="Task Logo" style={{ width: '50px', height: '50px' }} />
//                               </TableCell>
//                               <TableCell sx={{ color: 'white' }}>{task.taskName}</TableCell>
//                               <TableCell sx={{ color: 'white' }}>{task.taskPoints}</TableCell>
//                               <TableCell sx={{ color: 'white' }}>
//                                 <a href={task.socialUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
//                                   {task.socialUrl}
//                                 </a>
//                               </TableCell>
//                               <TableCell sx={{ color: 'white' }}>{task.isDisabled ? 'Yes' : 'No'}</TableCell>
//                             </TableRow>
//                           )}
//                         </Draggable>
//                       ))}
//                       {provided.placeholder}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               )}
//             </Droppable>
//           </DragDropContext>
//         )}

//         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//           <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
//             Previous
//           </Button>
//           <Typography sx={{ mx: 2 }}>
//             Page {currentPage} of {Math.ceil(tasks.length / tasksPerPage)}
//           </Typography>
//           <Button onClick={handleNextPage} disabled={currentPage === Math.ceil(tasks.length / tasksPerPage)}>
//             Next
//           </Button>
//         </Box>
//       </Card>

//       <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
//         <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
//           {error}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// export default TaskReorderComponent;





























import React, { useState, useEffect } from 'react';
import { 
  Typography, FormControl, InputLabel, Select, MenuItem, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, TextField, Box, Card, Snackbar, Alert,
  CircularProgress, Backdrop
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { backendServices } from '../services/backendService';

const TaskReorderComponent = () => {
  const categories = [
    { value: 'Register', label: 'Register' },
    { value: 'socials', label: 'Join Community' },
    { value: 'videos', label: 'Videos' },
    { value: 'Socialtask', label: 'Social Tasks' },
    { value: 'Partner', label: 'Partners Tasks' }
  ];

  // State management
  const [selectedCategory, setSelectedCategory] = useState('socials');
  const [tasks, setTasks] = useState([]);
  const [movedTaskId, setMovedTaskId] = useState('');
  const [newPosition, setNewPosition] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const tasksPerPage = 10;

  useEffect(() => {
    if (selectedCategory) {
      fetchTasks(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchTasks = async (category) => {
    setIsLoading(true);
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/task/getall?category=${category}`
      );
      setTasks(response.data.tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to fetch tasks: ' + (error.message || 'Unknown error occurred'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
    setMovedTaskId('');
    setNewPosition('');
  };

  const reorderTasksArray = (tasksList, movedId, newPos) => {
    const newIndex = newPos - 1;
    const taskToMove = tasksList.find(task => task.categoryOrderId === parseInt(movedId));
    
    if (!taskToMove) return tasksList;

    const remainingTasks = tasksList.filter(task => task.categoryOrderId !== parseInt(movedId));
    
    const updatedTasks = [
      ...remainingTasks.slice(0, newIndex),
      taskToMove,
      ...remainingTasks.slice(newIndex)
    ];

    return updatedTasks.map((task, index) => ({
      ...task,
      categoryOrderId: index + 1
    }));
  };

  const handleReorderTasks = async () => {
    // Input validation
    if (!movedTaskId || !newPosition) {
      setError("Please enter both moved task ID and new position");
      return;
    }

    const moveId = parseInt(movedTaskId);
    const newPos = parseInt(newPosition);

    if (isNaN(moveId) || isNaN(newPos)) {
      setError("Please enter valid numbers for task ID and position");
      return;
    }

    if (newPos > tasks.length || newPos < 1) {
      setError(`New position must be between 1 and ${tasks.length}`);
      return;
    }

    setIsLoading(true);
    try {
      // Optimistic update
      const updatedTasks = reorderTasksArray(tasks, moveId, newPos);
      setTasks(updatedTasks);

      // API call
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/task/reorder`,
        {
          category: selectedCategory,
          movedTaskId: moveId,
          newPosition: newPos,
        }
      );

      setSuccessMessage('Tasks reordered successfully');
      setMovedTaskId('');
      setNewPosition('');
    } catch (error) {
      console.error('Error reordering tasks:', error);
      setError('Failed to reorder tasks: ' + (error.message || 'Unknown error occurred'));
      // Revert to original order
      await fetchTasks(selectedCategory);
    } finally {
      setIsLoading(false);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    setIsLoading(true);
    try {
      const items = Array.from(tasks);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedItems = items.map((item, index) => ({
        ...item,
        categoryOrderId: index + 1
      }));

      setTasks(updatedItems);

      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/task/reorder`,
        {
          category: selectedCategory,
          movedTaskId: parseInt(reorderedItem.categoryOrderId, 10),
          newPosition: result.destination.index + 1
        }
      );

      setSuccessMessage('Task order updated successfully');
    } catch (error) {
      setError('Failed to update task order: ' + (error.message || 'Unknown error occurred'));
      await fetchTasks(selectedCategory);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return;
    setError(null);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') return;
    setSuccessMessage(null);
  };

  // Pagination calculations
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(tasks.length / tasksPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Typography variant="h4" sx={{ margin: '20px', color: 'white' }}>
        Reorder Tasks
      </Typography>
      
      <Card sx={{
        background: 'black',
        color: 'white',
        margin: '20px',
        padding: '20px',
        height: 'calc(100vh - 40px)',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <FormControl variant="standard" sx={{ width: '25%', color: 'white' }}>
            <InputLabel sx={{ color: 'white' }}>Select Category</InputLabel>
            <Select 
              value={selectedCategory} 
              onChange={handleCategoryChange} 
              sx={{ color: 'white' }}
              disabled={isLoading}
            >
              {categories.map((category) => (
                <MenuItem 
                  key={category.value} 
                  value={category.value} 
                  sx={{ background: 'black', color: 'white' }}
                >
                  {category.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Moved Task Order ID"
            value={movedTaskId}
            onChange={(e) => setMovedTaskId(e.target.value)}
            margin="normal"
            sx={{ width: '25%', color: 'white' }}
            InputLabelProps={{ style: { color: 'white' } }}
            inputProps={{ style: { color: 'white' } }}
            disabled={isLoading}
          />

          <TextField
            label="New Position"
            value={newPosition}
            onChange={(e) => setNewPosition(e.target.value)}
            margin="normal"
            type="number"
            sx={{ width: '25%', color: 'white' }}
            InputLabelProps={{ style: { color: 'white' } }}
            inputProps={{ style: { color: 'white' } }}
            disabled={isLoading}
          />

          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleReorderTasks} 
            sx={{ height: 'fit-content', alignSelf: 'center' }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Reorder Tasks'}
          </Button>
        </Box>

        {selectedCategory && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tasks">
              {(provided) => (
                <TableContainer 
                  component={Paper} 
                  sx={{ background: 'black', color: 'white' }} 
                  {...provided.droppableProps} 
                  ref={provided.innerRef}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: '#674eff' }}>Order Id</TableCell>
                        <TableCell sx={{ color: '#674eff' }}>Task Logo</TableCell>
                        <TableCell sx={{ color: '#674eff' }}>Task Name</TableCell>
                        <TableCell sx={{ color: '#674eff' }}>Task Points</TableCell>
                        <TableCell sx={{ color: '#674eff' }}>Social URL</TableCell>
                        <TableCell sx={{ color: '#674eff' }}>Is Disabled</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentTasks.map((task, index) => (
                        <Draggable 
                          key={task._id} 
                          draggableId={task._id.toString()} 
                          index={index}
                          isDragDisabled={isLoading}
                        >
                          {(provided) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableCell sx={{ color: 'white' }}>{task.categoryOrderId}</TableCell>
                              <TableCell sx={{ color: 'white' }}>
                                <img 
                                  src={task.taskLogoUrl} 
                                  alt="Task Logo" 
                                  style={{ width: '50px', height: '50px' }} 
                                />
                              </TableCell>
                              <TableCell sx={{ color: 'white' }}>{task.taskName}</TableCell>
                              <TableCell sx={{ color: 'white' }}>{task.taskPoints}</TableCell>
                              <TableCell sx={{ color: 'white' }}>
                                <a 
                                  href={task.socialUrl} 
                                  target="_blank" 
                                  rel="noopener noreferrer" 
                                  style={{ color: 'white' }}
                                >
                                  {task.socialUrl}
                                </a>
                              </TableCell>
                              <TableCell sx={{ color: 'white' }}>
                                {task.isDisabled ? 'Yes' : 'No'}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Droppable>
          </DragDropContext>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button 
            onClick={handlePreviousPage} 
            disabled={currentPage === 1 || isLoading}
          >
            Previous
          </Button>
          <Typography sx={{ mx: 2 }}>
            Page {currentPage} of {Math.ceil(tasks.length / tasksPerPage)}
          </Typography>
          <Button 
            onClick={handleNextPage} 
            disabled={currentPage === Math.ceil(tasks.length / tasksPerPage) || isLoading}
          >
            Next
          </Button>
        </Box>
      </Card>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleCloseError}
      >
        <Alert 
          onClose={handleCloseError} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!successMessage} 
        autoHideDuration={6000} 
        onClose={handleCloseSuccess}
      >
        <Alert 
          onClose={handleCloseSuccess} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TaskReorderComponent;